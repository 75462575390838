import css from './Footer.scss';
import {useTranslation, Trans} from 'react-i18next';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {getIsOneApp, getImpersonationInfo} from 'utilities/utils';
import Config from 'config';

const Footer = () => {
    const {t} = useTranslation('content', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const {vNum, tosUrl, privacyUrl, ndnUrl} = Config;
    const impersonationInfo = getImpersonationInfo();

    return (
        <>
            {!isOneApp && (
                <footer className={css.root}>
                    <div>
                        <div>&copy; {t('Footer.company')} </div>
                        <div> {t('Footer.allRightReserved')} </div>
                        <div className={css.dot}>&bull;</div>
                        <div>
                            {t('Footer.version')} {vNum}
                        </div>
                    </div>
                </footer>
            )}
            {isOneApp && (
                <footer className={css.oneAppFooterRoot}>
                    <div>
                        &copy; {t('Footer.teladoc')} &nbsp; • &nbsp;
                        {t('Footer.version')} {vNum}
                    </div>
                    <div className={css.oneAppAlignment}>
                        <span>
                            <Trans
                                i18nKey="content:Footer.privacyPolicy"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={privacyUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={css.link}
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                        <span className={css.url}>
                            <Trans
                                i18nKey="content:Footer.termsOfService"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={tosUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={css.link}
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                        <span>
                            <Trans
                                i18nKey="content:Footer.noticeOfNonDiscriminationAndAccessibility"
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={ndnUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={css.link}
                                    >
                                        <ScreenReader />
                                    </a>,
                                    /* eslint-enable react/jsx-key */
                                ]}
                            />
                        </span>
                    </div>
                    {impersonationInfo?.impersonatorId && (
                        <div className={css.impersonationFooter}>
                            <span>
                                Impersonator ID:{' '}
                                {impersonationInfo?.impersonatorId}
                            </span>
                            <span>
                                Impersonator type:{' '}
                                {impersonationInfo?.impersonatorType}
                            </span>
                            <span>
                                Impersonated ID:{' '}
                                {impersonationInfo?.impersonatedId}
                            </span>
                            <span>
                                Impersonated type:{' '}
                                {impersonationInfo?.impersonatedType}
                            </span>
                        </div>
                    )}
                </footer>
            )}
        </>
    );
};

export default Footer;
