import {useSelector} from 'react-redux';
import Image from '@teladoc/pulse/ui/Image';
import Button from '@teladoc/pulse/ui/Button';
import Config from 'config';
import cn from 'classnames';
import css from './Header.scss';
import {useTranslation} from 'react-i18next';
import {
    getUpsellURLParams,
    getIsOneApp,
    getImpersonationInfo,
} from 'utilities/utils';

const {memberPortalUrl} = Config;

const Header = () => {
    const isEmailQuestionAsked = useSelector(state => state.email);
    const {t} = useTranslation('common', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const impersonationInfo = getImpersonationInfo();

    const onLogInClick = evt => {
        // Todo: add data reset
        let memberPortalURL =
            process.env.MEMBER_PORTAL_URL ||
            `${window.location.protocol}//${window.location.hostname.replace(
                'program',
                'my'
            )}`;

        if (process.env.NODE_ENV === 'development') {
            // Change the port to your own if it is different.
            memberPortalURL = memberPortalUrl;
            // memberPortalURL = `${memberPortalURL}:3005`;
        }

        window.open(
            `${memberPortalURL}?${getUpsellURLParams(window.location.search)}`,
            '_self'
        );
    };

    if (isOneApp) {
        document.title = 'Teladoc Health';
        document.querySelector("link[rel*='icon']").href =
            'https://assets.livongo.com/image/upload/v1665515366/registration/common/favicon/TeledocFavIcon.png';
    }

    return (
        <>
            {isOneApp && impersonationInfo?.impersonatorId && (
                <div className={css.impersonation}>
                    You are impersonating member{' '}
                    {impersonationInfo?.impersonatedName
                        ?.replace('\\x20', ' ')
                        ?.replace('+', ' ')}
                </div>
            )}
            <div
                className={cn(css.root, {
                    [css.oneAppHeader]: isOneApp,
                    [css.impersonationHeight]: impersonationInfo,
                })}
            >
                {!isOneApp ? (
                    <Image
                        cloudinaryImageId="registration/common/Livongo_Logo_pulse_jqbaau"
                        width={120}
                        height={30}
                        alt="logo"
                    />
                ) : (
                    <Image
                        cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                        width={125}
                        height={40}
                        alt=""
                        showLoader={false}
                    />
                )}
                {!isOneApp && !isEmailQuestionAsked && (
                    <Button onClick={onLogInClick}>{t('buttons.login')}</Button>
                )}
            </div>
        </>
    );
};

export default Header;
